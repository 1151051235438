<template>
  <v-row justify="center">
    <!-- Kelola Webinar -->
    <v-col cols="12">
      <v-card rounded="lg">
        <v-card-title class="d-flex justify-space-between align-center">
          <span>
            Program Webinar
          </span>
          <v-btn
            small
            color="primary"
            depressed
            @click="
              dialog = true;
              listProgram(1);
            "
          >
            <v-icon left>ri-add-line</v-icon> Program Webinar
          </v-btn>
        </v-card-title>

        <v-row class="pa-4 pt-0">
          <v-col cols="12">Preview</v-col>
          <v-col cols="12" v-if="process.webinar">
            <v-skeleton-loader type="image"></v-skeleton-loader>
          </v-col>
          <v-col v-else cols="12">
            <div
              style="display: grid; gap: 15px;"
              :style="
                $vuetify.breakpoint.name == 'xs'
                  ? 'grid-template-columns: repeat(2, minmax(0, 1fr));'
                  : 'grid-template-columns: repeat(5, minmax(0, 1fr));'
              "
            >
              <div v-for="program in webinars.list" :key="program.id">
                <v-card hover ripple class="mx-auto rounded-lg d-flex flex-column" elevation="3" style="height: 100%">
                  <v-img
                    :src="getCover(program)"
                    :lazy-src="getCover(program)"
                    class="img-program rounded-t-lg"
                    style="height: 130px;max-height: 130px"
                  />
                  <div
                    class="pa-1 banner-class rounded-b-lg"
                    style="filter: drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08));"
                  >
                    <div
                      class="white--text font-weight-bold text-no-wrap"
                      :class="$vuetify.breakpoint.name == 'xs' ? 'caption px-1' : 'subtitle-2 px-4'"
                    >
                      Webinar
                    </div>
                  </div>
                  <v-card-text class="px-4">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          class="black--text font-weight-bold text-dots-2 fs-16 fs-xs-14"
                          >{{ program.title }}</span
                        >
                      </template>
                      <span class="text-subtitle-2">{{ program.title }}</span>
                    </v-tooltip>
                    <h3 class="font-weight-black primary--text">Rp{{ program.program_information.selling_price }}</h3>
                  </v-card-text>
                  <v-card-actions class="pt-2 mt-auto flex-column">
                    <div class="d-flex justify-center align-center ">
                      <v-btn
                        color="#F95712"
                        disabled
                        depressed
                        rounded
                        small
                        class="fs-14 font-weight-medium btn-beli white--text px-8"
                      >
                        Beli Pelatihan
                      </v-btn>
                    </div>
                    <div class="text-center mt-2">
                      <a class="text-subtitle-2" href="#" @click.prevent="deleteItem(program)">Hapus Program </a>
                    </div>
                  </v-card-actions>
                </v-card>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <!-- Kelola Pembelajaran Mandiri -->
    <v-col cols="12">
      <v-card rounded="lg">
        <v-card-title class="d-flex justify-space-between align-center">
          <span>
            Pembelajaran Mandiri
          </span>
          <v-btn
            small
            color="primary"
            depressed
            @click="
              dialog = true;
              listProgram(2);
            "
          >
            <v-icon left>ri-add-line</v-icon> Pembelajaran Mandiri
          </v-btn>
        </v-card-title>

        <v-row class="pa-4 pt-0">
          <v-col cols="12">Preview </v-col>
          <v-col cols="12" v-if="process.learnings">
            <v-skeleton-loader type="image"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" v-else>
            <div
              style="display: grid; gap: 15px;"
              :style="
                $vuetify.breakpoint.name == 'xs'
                  ? 'grid-template-columns: repeat(2, minmax(0, 1fr));'
                  : 'grid-template-columns: repeat(5, minmax(0, 1fr));'
              "
            >
              <div v-for="program in learnings.list" :key="program.id">
                <v-card hover ripple class="mx-auto rounded-lg d-flex flex-column" elevation="3" style="height: 100%">
                  <v-img
                    :src="getCover(program)"
                    :lazy-src="getCover(program)"
                    class="img-program rounded-t-lg"
                    style="height: 130px;max-height: 130px"
                  />
                  <div
                    class="pa-1 banner-class rounded-b-lg"
                    style="filter: drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08));"
                  >
                    <div
                      class="white--text font-weight-bold text-no-wrap"
                      :class="$vuetify.breakpoint.name == 'xs' ? 'caption px-1' : 'subtitle-2 px-4'"
                    >
                      Webinar
                    </div>
                  </div>
                  <v-card-text class="px-4">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          class="black--text font-weight-bold text-dots-2 fs-16 fs-xs-14"
                          >{{ program.title }}</span
                        >
                      </template>
                      <span class="text-subtitle-2">{{ program.title }}</span>
                    </v-tooltip>
                    <h3 class="font-weight-black primary--text">Rp{{ program.program_information.selling_price }}</h3>
                  </v-card-text>
                  <v-card-actions class="pt-2 mt-auto flex-column">
                    <div class="d-flex justify-center align-center ">
                      <v-btn
                        color="#F95712"
                        disabled
                        depressed
                        rounded
                        small
                        class="fs-14 font-weight-medium btn-beli white--text px-8"
                      >
                        Beli Pelatihan
                      </v-btn>
                    </div>
                    <div class="text-center mt-2">
                      <a class="text-subtitle-2" href="#" @click.prevent="deleteItem(program)">Hapus Program </a>
                    </div>
                  </v-card-actions>
                </v-card>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <!-- Kelola Luring -->
    <v-col cols="12">
      <v-card rounded="lg">
        <v-card-title class="d-flex justify-space-between align-center">
          <span>
            Program Luring
          </span>
          <v-btn
            small
            color="primary"
            depressed
            @click="
              dialog = true;
              listProgram(3);
            "
          >
            <v-icon left>ri-add-line</v-icon> Program Luring
          </v-btn>
        </v-card-title>

        <v-row class="pa-4 pt-0">
          <v-col cols="12">Preview </v-col>
          <v-col cols="12" v-if="process.luring">
            <v-skeleton-loader type="image"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" v-else>
            <div
              style="display: grid; gap: 15px;"
              :style="
                $vuetify.breakpoint.name == 'xs'
                  ? 'grid-template-columns: repeat(2, minmax(0, 1fr));'
                  : 'grid-template-columns: repeat(5, minmax(0, 1fr));'
              "
            >
              <div v-for="program in luring.list" :key="program.id">
                <v-card hover ripple class="mx-auto rounded-lg d-flex flex-column" elevation="3" style="height: 100%">
                  <v-img
                    :src="getCover(program)"
                    :lazy-src="getCover(program)"
                    class="img-program rounded-t-lg"
                    style="height: 130px;max-height: 130px"
                  />
                  <div
                    class="pa-1 banner-class rounded-b-lg"
                    style="filter: drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08));"
                  >
                    <div
                      class="white--text font-weight-bold text-no-wrap"
                      :class="$vuetify.breakpoint.name == 'xs' ? 'caption px-1' : 'subtitle-2 px-4'"
                    >
                      Webinar
                    </div>
                  </div>
                  <v-card-text class="px-4">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          class="black--text font-weight-bold text-dots-2 fs-16 fs-xs-14"
                          >{{ program.title }}</span
                        >
                      </template>
                      <span class="text-subtitle-2">{{ program.title }}</span>
                    </v-tooltip>
                    <h3 class="font-weight-black primary--text">Rp{{ program.program_information.selling_price }}</h3>
                  </v-card-text>
                  <v-card-actions class="pt-2 mt-auto flex-column">
                    <div class="d-flex justify-center align-center ">
                      <v-btn
                        color="#F95712"
                        disabled
                        depressed
                        rounded
                        small
                        class="fs-14 font-weight-medium btn-beli white--text px-8"
                      >
                        Beli Pelatihan
                      </v-btn>
                    </div>
                    <div class="text-center mt-2">
                      <a class="text-subtitle-2" href="#" @click.prevent="deleteItem(program)">Hapus Program </a>
                    </div>
                  </v-card-actions>
                </v-card>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <v-dialog v-model="dialog" max-width="600px" persistent>
      <v-card>
        <v-card-title>
          Tambahkan Program
        </v-card-title>
        <v-divider></v-divider>
        <div>
          <v-card-text>
            <v-col cols="12">
              <div class="mb-2">Program</div>
              <v-autocomplete
                :loading="loadingProgram"
                v-model="programs"
                :items="items"
                item-text="title"
                item-value="id"
                dense
                filled
                multiple
                chips
              >
              </v-autocomplete>
            </v-col>
          </v-card-text>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="primary"
            small
            elevation="0"
            @click="
              dialog = false;
              programs = [];
            "
            :disabled="loading"
          >
            Batal
          </v-btn>
          <v-btn class="white--text" color="primary" :loading="loading" small elevation="0" @click="onSave()">
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDelete" width="600px" persistent>
      <v-card elevation="0" rounded="lg" outlined>
        <v-card-title> <v-icon>$warning </v-icon> Hapus Program </v-card-title>
        <v-divider class="color--text mb-4"></v-divider>
        <v-card-text>
          <template>
            <span>Apakah anda yakin ingin menghapus program ini?</span>
          </template>
        </v-card-text>
        <v-card-actions class="pa-4" style="background: #f8f9fa">
          <v-spacer></v-spacer>
          <v-btn :disabled="process.run" outlined @click="confirmDelete = false"> Batal </v-btn>
          <v-btn @click="confirmDeleteItem" color="primary" elevation="0" :loading="process.run">
            Ya. Hapus!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" top :value="true" :color="snackbarColor" text outlined>
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn :color="snackbarColor" text v-bind="attrs" @click="snackbar = false" icon>
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import api from "@/models/program-webinar";

export default {
  components: {},
  data: () => ({
    confirmDelete: false,
    selected: undefined,
    timeout: undefined,
    webinars: {},
    learnings: {},
    luring: {},
    sorting: "",
    dialog: false,
    loading: false,
    loadingProgram: false,
    list_program: [],
    programs: [],
    checked: [],
    snackbar: false,
    snackbarColor: "success",
    message: "",
    process: {
      run: false,
    },
    params: {
      q: "",
      class_type: 1,
      show_in_dashboard: true,
      page: 1,
      limit: 10,
    },
  }),
  computed: {
    items() {
      return this.list_program.map((v) => {
        const disabled = this.programs.length >= 5 && !this.programs.includes(v.id);
        return { ...v, disabled };
      });
    },
  },

  mounted() {
    this.$emit("page-changed", 0, { title: "Kelola Dashboard", link: "/dashboard-settings" });
    this.fetch();
  },

  methods: {
    getCover(program) {
      return program.program_information?.cover?.url;
    },
    getPrice(number) {
      if (isNaN(number)) return "-";
      return parseFloat(number).toLocaleString("id-ID");
    },
    reset() {
      this.params.page = 1;
      this.params.limit = 10;
      this.fetch();
    },
    fetch(params = {}) {
      Object.assign(this.params, params);
      clearTimeout(this.timeout);
      this.timeout = undefined;
      this.timeout = setTimeout(async () => {
        Promise.all([this.fetchWebinar(), this.fetchLearning(), this.fetchLuring()]);
      }, 3e2);
    },
    async fetchWebinar() {
      this.$set(this.process, "webinar", true);
      this.params.class_type = 1;
      const res = await api
        .list({ ...this.params, class_type: 1 })
        .then((res) => res.data)
        .finally(() => {
          this.$set(this.process, "webinar", false);
        });
      this.webinars = res.data;
    },
    async fetchLearning() {
      this.$set(this.process, "learnings", true);
      this.params.class_type = 2;
      const res = await api
        .list({ ...this.params })
        .then((res) => res.data)
        .finally(() => {
          this.$set(this.process, "learnings", false);
        });
      this.learnings = res.data;
    },
    async fetchLuring() {
      this.$set(this.process, "luring", true);
      this.params.class_type = 3;
      const res = await api
        .list({ ...this.params })
        .then((res) => res.data)
        .finally(() => {
          this.$set(this.process, "luring", false);
        });
      this.luring = res.data;
    },
    deleteItem(item) {
      this.selected = item;
      this.confirmDelete = true;
    },
    async confirmDeleteItem() {
      this.process.run = true;
      api
        .deletePromgram(this.selected.id)
        .then(() => {
          this.confirmDelete = false;
          this.message = "Berhasil";
          this.snackbarColor = "success";
          if (this.selected.class_type == 1) this.fetchWebinar();
          if (this.selected.class_type == 2) this.fetchLearning();
          else this.fetchLuring();
        })
        .catch((e) => {
          const message = e.response?.data ? e.response.data.message : "Gagal";
          this.snackbarColor = "error";
          this.message = message;
        })
        .finally(() => {
          this.process.run = false;
          this.snackbar = true;
        });
    },
    async listProgram(class_type) {
      // 1 for webinar
      // 2 for pembelajaran mandiri
      this.params.class_type = class_type
      this.loadingProgram = true;
      this.list_program = await api
        .list({ status: "publish", limit: -1, class_type })
        .then((res) => res.data.data.list)
        .finally(() => {
          this.loadingProgram = false;
        });
      this.list_program.sort((v) => (v.show_in_dashboard ? -1 : 1));
      const selected = this.list_program.filter((v) => v.show_in_dashboard);

      this.programs = selected.map((v) => v.id);
      this.checked = selected.map((v) => v.id);
    },

    async onSave() {
      this.loading = true;
      api
        .addPrograms(this.programs, this.checked.filter(v => !this.programs.includes(v)))
        .then(() => {
          if (this.params.class_type == 1) this.fetchWebinar();
          if (this.params.class_type == 2) this.fetchLearning();
          else this.fetchLuring();
          this.dialog = false;
          this.message = "Berhasil";
          this.snackbarColor = "success";
          this.programs = []
        })
        .catch(() => {
          this.snackbarColor = "error";
          this.message = "Gagal";
        })
        .finally(() => {
          this.snackbar = true;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.banner-class {
  background: #e65c00; /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #f9a023, #f05326); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #f9a023,
    #f05326
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.banner-class-top {
  background: #e65c00; /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #f9a023, #f05326); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #f9a023,
    #f05326
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  position: absolute;
  width: max-content;
  border-radius: 10px 0 0 0;
  bottom: 0;
  right: 0;
}
.text-dots-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
