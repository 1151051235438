<template>
  <v-data-table
    :loading="isLoading"
    class="transparent"
    :headers="headers"
    :item-key="'id'"
    :items="data.list"
    :items-per-page.sync="data.limit"
    :page.sync="data.page"
    :mobile-breakpoint="0"
    loading-text="Loading... Please wait!"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    :footer-props="{
      'items-per-page-text':'Row per page : '
    }"
    :server-items-length="data.total"
  >
    <template v-slot:header.total="{ header }">
      <th v-if="sortDesc===false" @click="sortCalories" style="cursor: pointer;">{{ header.text }} <v-icon small>ri-arrow-up-line</v-icon></th>
      <th v-else @click="sortCalories" style="cursor: pointer;">{{ header.text }} <v-icon small>ri-arrow-down-line</v-icon></th>
    </template>
    <template slot="item" slot-scope="{ item }">
      <tr class="flat-b-1 rounded-lg">
        <td class="white rounded-l-lg" style="width: 50%">
          <router-link
            class="color--text font-weight-bold text-capitalize"
            :to="{ name: 'program-detail', params: { id: item.id } }"
            v-text="item.title"
          >
          </router-link>
        </td>
        <td class="white text-capitalize">{{ renderClassType(item.class_type) }}</td>
        <td class="white text-capitalize" v-text="item.learning_targets.studies.title"></td>
        <td class="white text-capitalize primary--text text-decoration-underline"><router-link :to="{name: 'list-user', params: { id: item.id }}">{{ item.total_participants }}</router-link></td>
        <td class="white text-capitalize">
          <v-chip
            v-if="item.status == 'archive'"
            :ripple="false"
            class="pa-1 text-capitalize"
            color="primary"
            outlined
            label
            small
            v-text="item.status"
          ></v-chip>
          <v-chip
            v-else
            :ripple="false"
            class="pa-1 text-capitalize"
            :color="item.status == 'draft' ? 'default' : 'success'"
            outlined
            label
            small
            v-text="item.status"
          ></v-chip>
        </td>
        <td class="white rounded-r-lg" style="width: 5%">
          <div class="d-flex flex-row">
            <can I="publish" a="Program">
            <v-btn title="Publish" :disabled="item.status == 'publish'" icon @click="$emit('update', item)"><v-icon>$publish</v-icon></v-btn>
            </can>
            <can I="publish" a="Program">
            <v-btn title="Archive" :disabled="item.status == 'archive'" icon @click="$emit('update', item)"><v-icon>$archive</v-icon></v-btn>
            </can>
            <v-menu offset-y>
              <template slot="activator" slot-scope="{ on, attrs }">
                <v-btn title="more" icon v-bind="attrs" v-on="on">
                  <v-icon>ri-more-line</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item link :to="{ name: 'list-user', params: { id: item.id } }">
                  <v-list-item-title>Total Peserta</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="$can('edit', 'Program')" link :to="{ name: 'edit-program', params: { id: item.id } }">
                  <v-list-item-title>Edit</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="$can('delete', 'Program')" link @click="$emit('remove', item)">
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>
<script>
export default {
  name: "program-table",
  props: {
    data: { required: true, type: Object, default: () => ({}) },
    loading: { type: Boolean, default: () => false },

  },
  data: () => ({
    sortBy: "total",
    sortDesc: false,
    headers: [
      { text: "Nama Program", class: "text-no-wrap", sortable: false, },
      { text: "Jenis Kelas", class: "text-no-wrap" },
      { text: "Bidang Studi", class: "text-no-wrap", sortable: false, },
      { text: "Total Peserta", value:"total",class: "text-no-wrap", sortable: false, },
      { text: "Status", class: "text-no-wrap", sortable: false, },
      { text: "Aksi", class: "text-no-wrap", sortable: false, },
    ],
    table_loading: false,
    
  }),
  computed: {
    isLoading() {
      return this.loading || this.table_loading;
    },
  },
  methods: {
    renderClassType(value) {
      let renderer = "";
      switch (value) {
        case 1:
          renderer = "Webinar";
          break;
        case 2:
          renderer = "Pembelajaran Mandiri";
          break;
        case 3:
          renderer = "Luring"
          break;
        default:
          break;
      }
      return renderer;
    },
    sortCalories() {
      if (this.sortDesc === false) {
        this.sortDesc = true;
      }else{
        this.sortDesc = false;        
      }
      // console.log('sort',this.sortDesc)
      this.$emit("sorting",this.sortDesc);
    },
    paginate: (current, total) => {
      if (current > total) {
        throw new Error("Current page is larger than total");
      }
      let pages = [];
      if (total > 5) {
        pages = new Array(5);
        pages = pages.fill(0).map((val, i) => {
          if (current > 3 && current < total - 2) {
            switch (i) {
              case 0:
                return current > 3 ? "..." : i + current - 2;
              case 4:
                return current < total - 2 ? "..." : i + current - 2;
              default:
                return i + current - 2;
            }
          }

          if (current <= 3) {
            return i == 4 ? "..." : i + 1;
          }

          if (current >= total - 2) {
            return i == 0 ? "..." : i + total - 4;
          }
        });
      } else {
        pages = new Array(total);
        pages = pages.fill(0).map((val, i) => i + 1);
      }

      return pages;
    },
  },
  watch: {
    "data.limit": function () {
      this.$emit("refresh-list");
    },
    "data.page": function () {
      this.$emit("refresh-list");
    },
  }
};
</script>
<style>
  #dt-program .v-data-table > .v-data-table__wrapper > table {
    border-spacing: 0 10px !important;
  }
  #dt-program .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    height: 80px !important;
  }
  .theme--light #dt-program .v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th{
    border:0px solid!important;
  }
  .theme--light #dt-program .v-data-table .v-data-footer {
    border:0px solid!important;    
  }
  #dt-program .v-data-footer__select {
    justify-content: left !important;
    position: absolute;
    left: 0;
  }
  .v-text-field--outlined fieldset{
    border:0px solid;
  }
  .v-application--is-ltr #dt-program .v-data-footer__select .v-select {
    margin: 13px 0 13px 0px;
  }
</style>