var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"rounded":"lg"}},[_c('v-card-title',{staticClass:"d-flex justify-space-between align-center"},[_c('span',[_vm._v(" Program Webinar ")]),_c('v-btn',{attrs:{"small":"","color":"primary","depressed":""},on:{"click":function($event){_vm.dialog = true;
            _vm.listProgram(1);}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("ri-add-line")]),_vm._v(" Program Webinar ")],1)],1),_c('v-row',{staticClass:"pa-4 pt-0"},[_c('v-col',{attrs:{"cols":"12"}},[_vm._v("Preview")]),(_vm.process.webinar)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"image"}})],1):_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticStyle:{"display":"grid","gap":"15px"},style:(_vm.$vuetify.breakpoint.name == 'xs'
                ? 'grid-template-columns: repeat(2, minmax(0, 1fr));'
                : 'grid-template-columns: repeat(5, minmax(0, 1fr));')},_vm._l((_vm.webinars.list),function(program){return _c('div',{key:program.id},[_c('v-card',{staticClass:"mx-auto rounded-lg d-flex flex-column",staticStyle:{"height":"100%"},attrs:{"hover":"","ripple":"","elevation":"3"}},[_c('v-img',{staticClass:"img-program rounded-t-lg",staticStyle:{"height":"130px","max-height":"130px"},attrs:{"src":_vm.getCover(program),"lazy-src":_vm.getCover(program)}}),_c('div',{staticClass:"pa-1 banner-class rounded-b-lg",staticStyle:{"filter":"drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08))"}},[_c('div',{staticClass:"white--text font-weight-bold text-no-wrap",class:_vm.$vuetify.breakpoint.name == 'xs' ? 'caption px-1' : 'subtitle-2 px-4'},[_vm._v(" Webinar ")])]),_c('v-card-text',{staticClass:"px-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"black--text font-weight-bold text-dots-2 fs-16 fs-xs-14"},'span',attrs,false),on),[_vm._v(_vm._s(program.title))])]}}],null,true)},[_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(program.title))])]),_c('h3',{staticClass:"font-weight-black primary--text"},[_vm._v("Rp"+_vm._s(program.program_information.selling_price))])],1),_c('v-card-actions',{staticClass:"pt-2 mt-auto flex-column"},[_c('div',{staticClass:"d-flex justify-center align-center "},[_c('v-btn',{staticClass:"fs-14 font-weight-medium btn-beli white--text px-8",attrs:{"color":"#F95712","disabled":"","depressed":"","rounded":"","small":""}},[_vm._v(" Beli Pelatihan ")])],1),_c('div',{staticClass:"text-center mt-2"},[_c('a',{staticClass:"text-subtitle-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.deleteItem(program)}}},[_vm._v("Hapus Program ")])])])],1)],1)}),0)])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"rounded":"lg"}},[_c('v-card-title',{staticClass:"d-flex justify-space-between align-center"},[_c('span',[_vm._v(" Pembelajaran Mandiri ")]),_c('v-btn',{attrs:{"small":"","color":"primary","depressed":""},on:{"click":function($event){_vm.dialog = true;
            _vm.listProgram(2);}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("ri-add-line")]),_vm._v(" Pembelajaran Mandiri ")],1)],1),_c('v-row',{staticClass:"pa-4 pt-0"},[_c('v-col',{attrs:{"cols":"12"}},[_vm._v("Preview ")]),(_vm.process.learnings)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"image"}})],1):_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticStyle:{"display":"grid","gap":"15px"},style:(_vm.$vuetify.breakpoint.name == 'xs'
                ? 'grid-template-columns: repeat(2, minmax(0, 1fr));'
                : 'grid-template-columns: repeat(5, minmax(0, 1fr));')},_vm._l((_vm.learnings.list),function(program){return _c('div',{key:program.id},[_c('v-card',{staticClass:"mx-auto rounded-lg d-flex flex-column",staticStyle:{"height":"100%"},attrs:{"hover":"","ripple":"","elevation":"3"}},[_c('v-img',{staticClass:"img-program rounded-t-lg",staticStyle:{"height":"130px","max-height":"130px"},attrs:{"src":_vm.getCover(program),"lazy-src":_vm.getCover(program)}}),_c('div',{staticClass:"pa-1 banner-class rounded-b-lg",staticStyle:{"filter":"drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08))"}},[_c('div',{staticClass:"white--text font-weight-bold text-no-wrap",class:_vm.$vuetify.breakpoint.name == 'xs' ? 'caption px-1' : 'subtitle-2 px-4'},[_vm._v(" Webinar ")])]),_c('v-card-text',{staticClass:"px-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"black--text font-weight-bold text-dots-2 fs-16 fs-xs-14"},'span',attrs,false),on),[_vm._v(_vm._s(program.title))])]}}],null,true)},[_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(program.title))])]),_c('h3',{staticClass:"font-weight-black primary--text"},[_vm._v("Rp"+_vm._s(program.program_information.selling_price))])],1),_c('v-card-actions',{staticClass:"pt-2 mt-auto flex-column"},[_c('div',{staticClass:"d-flex justify-center align-center "},[_c('v-btn',{staticClass:"fs-14 font-weight-medium btn-beli white--text px-8",attrs:{"color":"#F95712","disabled":"","depressed":"","rounded":"","small":""}},[_vm._v(" Beli Pelatihan ")])],1),_c('div',{staticClass:"text-center mt-2"},[_c('a',{staticClass:"text-subtitle-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.deleteItem(program)}}},[_vm._v("Hapus Program ")])])])],1)],1)}),0)])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"rounded":"lg"}},[_c('v-card-title',{staticClass:"d-flex justify-space-between align-center"},[_c('span',[_vm._v(" Program Luring ")]),_c('v-btn',{attrs:{"small":"","color":"primary","depressed":""},on:{"click":function($event){_vm.dialog = true;
            _vm.listProgram(3);}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("ri-add-line")]),_vm._v(" Program Luring ")],1)],1),_c('v-row',{staticClass:"pa-4 pt-0"},[_c('v-col',{attrs:{"cols":"12"}},[_vm._v("Preview ")]),(_vm.process.luring)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"image"}})],1):_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticStyle:{"display":"grid","gap":"15px"},style:(_vm.$vuetify.breakpoint.name == 'xs'
                ? 'grid-template-columns: repeat(2, minmax(0, 1fr));'
                : 'grid-template-columns: repeat(5, minmax(0, 1fr));')},_vm._l((_vm.luring.list),function(program){return _c('div',{key:program.id},[_c('v-card',{staticClass:"mx-auto rounded-lg d-flex flex-column",staticStyle:{"height":"100%"},attrs:{"hover":"","ripple":"","elevation":"3"}},[_c('v-img',{staticClass:"img-program rounded-t-lg",staticStyle:{"height":"130px","max-height":"130px"},attrs:{"src":_vm.getCover(program),"lazy-src":_vm.getCover(program)}}),_c('div',{staticClass:"pa-1 banner-class rounded-b-lg",staticStyle:{"filter":"drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08))"}},[_c('div',{staticClass:"white--text font-weight-bold text-no-wrap",class:_vm.$vuetify.breakpoint.name == 'xs' ? 'caption px-1' : 'subtitle-2 px-4'},[_vm._v(" Webinar ")])]),_c('v-card-text',{staticClass:"px-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"black--text font-weight-bold text-dots-2 fs-16 fs-xs-14"},'span',attrs,false),on),[_vm._v(_vm._s(program.title))])]}}],null,true)},[_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(program.title))])]),_c('h3',{staticClass:"font-weight-black primary--text"},[_vm._v("Rp"+_vm._s(program.program_information.selling_price))])],1),_c('v-card-actions',{staticClass:"pt-2 mt-auto flex-column"},[_c('div',{staticClass:"d-flex justify-center align-center "},[_c('v-btn',{staticClass:"fs-14 font-weight-medium btn-beli white--text px-8",attrs:{"color":"#F95712","disabled":"","depressed":"","rounded":"","small":""}},[_vm._v(" Beli Pelatihan ")])],1),_c('div',{staticClass:"text-center mt-2"},[_c('a',{staticClass:"text-subtitle-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.deleteItem(program)}}},[_vm._v("Hapus Program ")])])])],1)],1)}),0)])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Tambahkan Program ")]),_c('v-divider'),_c('div',[_c('v-card-text',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-2"},[_vm._v("Program")]),_c('v-autocomplete',{attrs:{"loading":_vm.loadingProgram,"items":_vm.items,"item-text":"title","item-value":"id","dense":"","filled":"","multiple":"","chips":""},model:{value:(_vm.programs),callback:function ($$v) {_vm.programs=$$v},expression:"programs"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"primary","small":"","elevation":"0","disabled":_vm.loading},on:{"click":function($event){_vm.dialog = false;
            _vm.programs = [];}}},[_vm._v(" Batal ")]),_c('v-btn',{staticClass:"white--text",attrs:{"color":"primary","loading":_vm.loading,"small":"","elevation":"0"},on:{"click":function($event){return _vm.onSave()}}},[_vm._v(" Simpan ")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"600px","persistent":""},model:{value:(_vm.confirmDelete),callback:function ($$v) {_vm.confirmDelete=$$v},expression:"confirmDelete"}},[_c('v-card',{attrs:{"elevation":"0","rounded":"lg","outlined":""}},[_c('v-card-title',[_c('v-icon',[_vm._v("$warning ")]),_vm._v(" Hapus Program ")],1),_c('v-divider',{staticClass:"color--text mb-4"}),_c('v-card-text',[[_c('span',[_vm._v("Apakah anda yakin ingin menghapus program ini?")])]],2),_c('v-card-actions',{staticClass:"pa-4",staticStyle:{"background":"#f8f9fa"}},[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.process.run,"outlined":""},on:{"click":function($event){_vm.confirmDelete = false}}},[_vm._v(" Batal ")]),_c('v-btn',{attrs:{"color":"primary","elevation":"0","loading":_vm.process.run},on:{"click":_vm.confirmDeleteItem}},[_vm._v(" Ya. Hapus! ")])],1)],1)],1),_c('v-snackbar',{attrs:{"top":"","value":true,"color":_vm.snackbarColor,"text":"","outlined":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
            var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":_vm.snackbarColor,"text":"","icon":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("$close")])],1)]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }